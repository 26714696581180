/* eslint-disable array-bracket-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useLayoutEffect, useState, useMemo } from 'react';
import track from 'utils/amplitudeWrapper';
import { useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import RelocatePositionModal from 'components/RelocatePositionModal/RelocatePositionModal';
import { Spinner } from 'components/Spinner';
import { GetRecommendationModal } from 'components/GetRecommendationModal';
import ProgressBar from 'components/ProgressBar';
import Header from 'containers/Header/Header';
import TabsView from 'components/TabsView';
import { useFlowActions } from 'context/actions/flowActions';
import { FlowSteps, OptionType } from 'context/store/flowReducer';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { ActionTypes, useApiDataActions } from 'context/actions/ApiDataActions';
import { useAppDispatch, useAppState } from 'context/AppState';
import routes from 'base/constants/routes';
import openMapNotification from 'utils/openMapNotification';
import { useBreakpoint } from 'hooks';
import AgronomicWeightingsDrawer from 'components/AgronomicWeightingsDrawer';

import {
  ContentView,
  ContentViewMobile,
  LandscapeWarningMessageContainer,
  Root,
} from './DropAPinFlow.styles';
import DropAPinMap from 'containers/DropAPinMapView';
import useIsMounted from 'hooks/useIsMounted';
import { AgronomicWeighting } from 'context/store/recommendationFormReducer';
import { hideCookiebotIcon } from 'utils/helpers/cookiebot';

import SecondStep from 'containers/SecondStep';

import useValidationSteps from './hooks/useValidationSteps';
import PinInformationSider from 'components/PinInformationSider';
import { CropConstants } from 'utils/constants/Crop';
import { AgInputType } from 'base/types/AmplitudeClassifiers';
import { RotationIntensity, RotationIntensityforDowny } from 'utils/constants/RotationIntensity';
import { calculateSoilType } from 'utils/calculateSoilType';
import { TillagePractice, TillagePracticeForTraits } from 'utils/constants/TillagePractice';
import { trackAgweightings, trackAdjustInputPopUp, trackSunflowerInputs } from 'utils/helpers/amplitude';
import { formatYieldRangeLabel } from 'utils/yieldRange';
import { ProductListEmptyReponse, checkIfProductListIsEmpty } from 'utils/helpers/filterProducts';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { ORIENTATION_BASED_DISPLAY, ORIENTATION_TYPE } from 'utils/constants/BrowserConstants';
import {
  retrievePinsData,
  retrieveRecommendationInputData,
} from 'utils/getRecommendationInputData';
import { DownyMildewResponse } from 'base/types/DownyMildew';
import { Country } from 'base/types/Countries';
import { getCentroidForCircle, getCentroidOfFields } from 'utils/getCentroidFields';
import { AttributesForFields } from 'base/types/Fields';
import { ICrop } from 'base/types/Crop';
import { checkIfNoSeedsVarietiesAvailable } from 'utils/getRecommendedSeeds';

interface DropAPinProps {
  isMobileLongTermChanges?: boolean;
}

export default function DropAPinFlow(props: DropAPinProps) {
  const { isMobileLongTermChanges } = props;
  const { isMobile, orientation } = useBreakpoint();
  const flowActions = useFlowActions();
  const ApiDataActions = useApiDataActions();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation();
  const isMounted = useIsMounted();
  const appDispatcher = useAppDispatch();
  const recommendationFormActions = useRecommendationFormActions();

  const [isPinInfoValid, setIsPinInfoValid] = useState(true);
  const [pinSize, setPinSize] = useState('12');
  const [checkProductListEmptyResponse, setCheckProductListEmptyResponse] = useState({
    errorMsg: '',
    isProductListEmpty: false,
  });
  const {
    flow: {
      currentStep,
      optionType,
      showPinWarning,
      showSpinner,
      showGetRecommendationModal,
      localCountry,
      mobileActiveTab,
      showAgronomicWeightings,
      journeyCompleted,
      showPrompt,
      isAnotherRecommendationCreated,
      showLandIsNotArableModal,
    },
    recommendationForm: {
      selectedCrop,
      fields,
      pin,
      agronomicInputs,
      agronomicWeightings,
      countryCode,
      countryRegion,
      rankingCrop,
    },
    apiData: {
      attributes,
      tppData,
      downyMildew,
      fieldsAttributesLoading,
      recommenationValidateLoading,
      noSeedsAvailable,
      fieldsAttributes,
      countryList,
      arableLandLoading,
    },
  } = useAppState();

  const {
    plantingDate,
    yieldRangeId,
    yieldRangeLabel,
    harvestDate,
    agProducts,
    rotationIntensity,
    tillagePractice,
    selectedHerbicideTrait,
    selectedSegment,
    isBroomrapeInfestation,
    selectedBroomrape,
  } = agronomicInputs;

  const { previousStepMap, disabledNextBtn, disabledPrevBtn, nextStepMap, nextButtonText } =
    useValidationSteps({
      currentStep,
      agProducts,
      plantingDate,
      selectedCrop,
      yieldRangeId,
      isPinInfoValid,
      rotationIntensity,
      tillagePractice,
      selectedHerbicideTrait,
      selectedSegment,
      isBroomrapeInfestation,
      selectedBroomrape,
      isAnotherRecommendationCreated,
    });

  const { isValidationApiCheckEnabled, isLongTermChangesEnable } = useFlags();

  useLayoutEffect(() => {
    hideCookiebotIcon();
  }, []);

  useEffect(() => {
    // Redirect to Home page
    if (optionType !== OptionType.Drop) {
      navigate('/');
    }
    flowActions.setIsLongTermMobileDesign({
      isLongTermMobileDesign: isMobile && isLongTermChangesEnable,
    });
  }, [navigate, optionType]);

  useEffect(() => {
    const handleTabClose = () => {
      if (!journeyCompleted) {
        track('journey', { 'journey pin completed': false });
      }
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => window.removeEventListener('beforeunload', handleTabClose);
  }, [journeyCompleted]);

  useEffect(() => {
    if (checkProductListEmptyResponse.isProductListEmpty || noSeedsAvailable) {
      trackAdjustInputPopUp(checkProductListEmptyResponse.isProductListEmpty, noSeedsAvailable);
    }
  }, [noSeedsAvailable, checkProductListEmptyResponse]);

  useLayoutEffect(() => {
    if (!isMounted() && currentStep !== FlowSteps.STEP2) {
      openMapNotification({
        id: 'dropPinHelp',
        msg: 'Pan the map view to place the pin at the desired location.',
        width: 445,
        className: 'drop-pin-help-popup',
        placement: isMobile ? 'top' : 'bottom',
      });
    }
  }, [isMounted, optionType]);

  const validateRecommendationApi = async (
    dmValue?: DownyMildewResponse,
    fieldAttributeResponse?: AttributesForFields
  ) => {
    const otherAgInput = {
      tppData,
      downyMildew: dmValue,
      selectedCrop,
    };
    const nextStep = nextStepMap[currentStep];
    try {
      const pins = retrievePinsData(fields, pin);
      const recommendationInput = retrieveRecommendationInputData(
        agronomicInputs,
        otherAgInput,
        i18n,
        countryCode,
        countryRegion,
        agronomicWeightings,
        rankingCrop,
        t,
        countryList,
        attributes,
        fieldAttributeResponse,
      );
      const body = {
        recommendationInput,
        pins,
      };
      await ApiDataActions.getRecommendationValidatedBeforeHand(body).then((result) => {
        const hasNoneValue = checkIfNoSeedsVarietiesAvailable(result);
        if (!hasNoneValue && nextStep) {
          flowActions.setFlowStep({ step: nextStep });
        }
        flowActions.setShowGetRecommendationModal({ show: true });
      });
    } catch (e) {
      flowActions.setShowGetRecommendationModal({ show: false });
      appDispatcher({
        type: ActionTypes.setDownyMildewError,
        payload: { error: e },
      });
    }
  };

  const getCountryName = () => {
    const selectedCountry = countryList?.find((item: Country) => item.shortCode === countryCode);
    return selectedCountry?.name;
  };

  const fetchFieldAttributesToFilterProducts = async (dmValue: any) => {
    let centroid: number[] = [];
    if (fields.length) {
      if (optionType === OptionType.Draw) {
        centroid = getCentroidOfFields(fields);
      } else if (optionType === OptionType.Drop) {
        centroid = getCentroidForCircle(fields);
      }
    }
    try {
      if (centroid.length > 0) {
        const lat = centroid[1];
        const long = centroid[0];
        await ApiDataActions.getFieldAttributes(getCountryName(), selectedCrop?.id, long, lat)
          .then((fieldAttributeResponse) => {
            appDispatcher({
              type: ActionTypes.setFieldsAttribute,
              payload: { data: fieldAttributeResponse },
            });
            validateRecommendationApi(dmValue, fieldAttributeResponse);
          })
          .catch(() => {
            validateRecommendationApi(dmValue);
          });
      }
    } catch (e) {
      appDispatcher({
        type: ActionTypes.setFieldsAttributeError,
        payload: { error: e },
      });
    }
  };

  const checkBeforeGenerateRecommendation = async (dmValue?: DownyMildewResponse) => {
    const otherAgInput = {
      tppData,
      downyMildew: dmValue,
      selectedCrop,
    };
    const result: ProductListEmptyReponse = checkIfProductListIsEmpty(
      otherAgInput,
      agronomicInputs,
      t,
      countryCode,
      fieldsAttributes
    );
    setCheckProductListEmptyResponse(result);

    if (!result?.isProductListEmpty) {
      // added a flag to manage validation api check
      if (isValidationApiCheckEnabled) {
        if (countryCode === CropConstants.BRAZIL_CODE) {
          await fetchFieldAttributesToFilterProducts(dmValue);
        } else {
          await validateRecommendationApi(dmValue);
        }
      } else {
        const nextStep = nextStepMap[currentStep];
        if (nextStep) {
          flowActions.setFlowStep({ step: nextStep });
        }
        flowActions.setShowGetRecommendationModal({ show: true });
      }
    }
  };

  const getDownyMildew = useCallback(async () => {
    try {
      if (
        rotationIntensity &&
        tillagePractice &&
        tillagePractice !== TillagePractice.NO_TILLAGE &&
        tppData.TPP_group &&
        tppData.TPP_group.length &&
        fields
      ) {
        const rotation =
          rotationIntensity === RotationIntensity.Extensive
            ? RotationIntensityforDowny.Extensive
            : RotationIntensityforDowny.Intensive;

        const tillPractice =
          tillagePractice === TillagePractice.PLOUGHING
            ? TillagePracticeForTraits.PLOUGHING
            : TillagePracticeForTraits.MINIMAL_TILLAGE;

        const dmValue = await ApiDataActions.getDownyMildew(
          tppData.TPP_group[0],
          rotation,
          tillPractice,
          calculateSoilType(fields)
        );

        if (dmValue.value) {
          checkBeforeGenerateRecommendation(dmValue);
        }
      }
      flowActions.setShowGetRecommendationModal({ show: true });
    } catch (e) {
      appDispatcher({
        type: ActionTypes.setDownyMildewError,
        payload: { error: e },
      });
    }
  }, [
    tppData,
    rotationIntensity,
    tillagePractice,
    fields,
    selectedHerbicideTrait,
    selectedBroomrape,
    selectedSegment,
    plantingDate,
    harvestDate,
    downyMildew,
  ]);

  const handlePreviousAction = useCallback(() => {
    if (isMobile) {
      flowActions.setCurrentStep({ step: FlowSteps.STEP1 });
      flowActions.setShowFieldInformationSheet({ showFieldInformationSheet: true });
      navigate(routes.home);
    } else {
      const emptyCrop: ICrop = {
        id: 0,
        name: '',
        type: '',
      };
      switch (currentStep) {
        case FlowSteps.STEP2:
          recommendationFormActions.setPinHectaresSize({
            hectaresSize: Number.parseFloat(pinSize),
          });
          flowActions.setArePinConfirmed({ arePinConfirmed: false });
          recommendationFormActions.setSelectedCrop({ crop: emptyCrop });
          break;
        default:
          break;
      }
      const previousStep = previousStepMap[currentStep];
      if (previousStep) {
        flowActions.setFlowStep({ step: previousStep });
      }
    }
  }, [currentStep, isMobile, flowActions, pinSize, recommendationFormActions, previousStepMap]);

  const handleNextAction = useCallback(() => {
    // Next button handler for Step 1 is getting called from PinInformationSider.tsx(desktop) and DropAPinMapView.tsx(mobile)
    switch (currentStep) {
      case FlowSteps.STEP2:
        if (selectedCrop?.name) {
          /*
           * agInputs for testing none usecase for planting date - Start
           */
          const agInputs: AgInputType = {
            'ag input crop': selectedCrop.name,
          };
          if (yieldRangeLabel.length > 0) {
            agInputs['yield range'] = formatYieldRangeLabel(yieldRangeLabel);
          }
          if (plantingDate) {
            agInputs['planting date'] = plantingDate?.format('YYYY-MM-DD');
          }
          if (harvestDate) {
            agInputs['harvest date'] = harvestDate.format('YYYY-MM-DD');
            track('harvest date', { 'harvest date selected': true });
          }
          track('agronomic inputs', { ...agInputs });
          /*
           *  agInputs for testing none usecase for planting date - End
           */
        }
        if (selectedCrop?.id === CropConstants.SUNFLOWER_CROP_ID) {
          getDownyMildew();
          trackSunflowerInputs(agronomicInputs);
        } else {
          checkBeforeGenerateRecommendation();
        }
        trackAgweightings({agronomicWeightings});
        break;
      default:
        break;
    }
  }, [
    selectedCrop,
    currentStep,
    flowActions,
    harvestDate,
    isPinInfoValid,
    plantingDate,
    yieldRangeId,
    selectedHerbicideTrait,
    selectedBroomrape,
    selectedSegment,
    tppData,
    downyMildew,
    selectedCrop,
    agronomicInputs,
    agronomicWeightings
  ]);

  const onCancelRecommendationModal = useCallback(() => {
    flowActions.setShowGetRecommendationModal({ show: false });
    flowActions.setFlowStep({ step: FlowSteps.STEP2 });
  }, [flowActions]);

  const onGetRecommendationModal = useCallback(
    (status: string) => {
      if (status === 'in-progress') {
        navigate(routes.recommendationInProgress);
      } else {
        navigate(routes.confirmation);
      }
    },
    [navigate]
  );

  const onOkRelocatePinModal = useCallback(() => {
    flowActions.setShowPinWarning({ show: false });
  }, []);

  const handleSetShowAgronomicWeightings = useCallback((showAgronomicWeightings: boolean) => {
    flowActions.setShowAgronomicWeightings({ show: showAgronomicWeightings });
  }, []);

  const handleFillAgronomicWeightings = useCallback((agronomicWeighting: AgronomicWeighting[]) => {
    recommendationFormActions.fillAgronomicWeightings({ agronomicWeighting: agronomicWeighting });
  }, []);

  const stepComponentMap = useMemo(
    (): { [key in FlowSteps]: JSX.Element | undefined } => ({
      [FlowSteps.STEP1]: (
        <PinInformationSider
          id="leftPanel"
          title={`${t('Step {{stepNumber}}', { stepNumber: 1 })} : ${t('Drop a pin')}`}
          description={t('Drop a pin instructions')}
          setIsValid={setIsPinInfoValid}
          defaultSize={pinSize}
          setPinSize={setPinSize}
        />
      ),
      [FlowSteps.STEP2]: (
        <SecondStep
          onClickPrev={handlePreviousAction}
          onClickNext={handleNextAction}
          isCornSelected={
            selectedCrop?.name === CropConstants.CORN_SMALLCASE ||
            selectedCrop?.name === CropConstants.SILAGE_CORN_SMALLCASE ||
            selectedCrop?.name === CropConstants.SUMMER_CORN_SMALLCASE
          }
          isMobile={isMobile}
        />
      ),
      [FlowSteps.STEP3]: (
        <SecondStep
          onClickPrev={handlePreviousAction}
          onClickNext={handleNextAction}
          isCornSelected={
            selectedCrop?.name === CropConstants.CORN_SMALLCASE ||
            selectedCrop?.name === CropConstants.SILAGE_CORN_SMALLCASE ||
            selectedCrop?.name === CropConstants.SUMMER_CORN_SMALLCASE
          }
          isMobile={isMobile}
        />
      ),
      [FlowSteps.STEP4]: undefined,
      [FlowSteps.empty]: undefined,
    }),
    [
      handleNextAction,
      handlePreviousAction,
      t,
      PinInformationSider,
      pinSize,
      setIsPinInfoValid,
      setPinSize,
    ]
  );

  const RightPanel = useMemo(
    () =>
      ({ id }: { id: string }) =>
        <DropAPinMap id={id} />,
    []
  );

  const mobileTabsItems = useMemo(
    () => [
      {
        key: 'Map',
        label: 'Map',
        children: <RightPanel id="flowMapMobile" />,
        forceRender: true,
      },
      {
        key: 'List',
        label: 'List',
        children: stepComponentMap[currentStep],
        forceRender: true,
        disabled: false,
      },
    ],
    [RightPanel, stepComponentMap, currentStep]
  );

  usePrompt({
    when: pathname === routes.wizardDropAPin && showPrompt && !isMobile,
    message: t('Are you sure you want to navigate away from this page? All progress will be lost.'),
  });

  const handleShowLandNotArableModal = () => {
    flowActions.setShowLandIsNotArableModal({ show: false });
  };
  return (
    <>
      <Root
        className={
          isMobile && orientation === ORIENTATION_TYPE.LANDSCAPE
            ? ORIENTATION_BASED_DISPLAY.HIDE_ON_LANDSCAPE
            : ''
        }
      >
        <Header />
        {(showSpinner || recommenationValidateLoading || fieldsAttributesLoading) && <Spinner />}
        <ProgressBar
          disabledPrevBtn={disabledPrevBtn}
          handlePreviousAction={handlePreviousAction}
          disabledNextBtn={disabledNextBtn}
          handleNextAction={handleNextAction}
          nextStepText={nextButtonText}
          showProgressLine
        />
        {isMobileLongTermChanges ? (
          <ContentViewMobile>
            <SecondStep
              onClickPrev={handlePreviousAction}
              onClickNext={handleNextAction}
              isCornSelected={
                selectedCrop?.name === CropConstants.CORN_SMALLCASE ||
                selectedCrop?.name === CropConstants.SILAGE_CORN_SMALLCASE ||
                selectedCrop?.name === CropConstants.SUMMER_CORN_SMALLCASE
              }
              isMobile={true}
            />
          </ContentViewMobile>
        ) : (
          <ContentView>
            {isMobile === false ? (
              <>
                {stepComponentMap[currentStep]}
                <RightPanel id="flowMap" />
              </>
            ) : (
              <TabsView
                items={mobileTabsItems}
                activeKey={mobileActiveTab}
                onChange={(selectedKey) =>
                  flowActions.setMobileActiveTab({ activeTab: selectedKey as any })
                }
              />
            )}
          </ContentView>
        )}

        {showGetRecommendationModal &&
          !recommenationValidateLoading &&
          (checkProductListEmptyResponse.isProductListEmpty ? (
            <ConfirmationModal
              id={'ServiceUnavailableModal'}
              title={t('Please note')}
              body={checkProductListEmptyResponse.errorMsg}
              cancelButtonText={t('Adjust inputs')}
              onClickCancel={() => flowActions.setShowGetRecommendationModal({ show: false })}
              buttonStyle="primary"
            />
          ) : noSeedsAvailable ? (
            <ConfirmationModal
              id={'ServiceUnavailableModal'}
              title={t('Please note')}
              body={t(
                'There are no seed varieties matching the provided criteria. Try changing the inputs please.'
              )}
              cancelButtonText={t('Adjust inputs')}
              onClickCancel={() => flowActions.setShowGetRecommendationModal({ show: false })}
              buttonStyle="primary"
            />
          ) : (
            <GetRecommendationModal
              isDropAPinFlow
              title={t('Get Recommendation')}
              description={t('Get Recommendation description')}
              onCancelClick={onCancelRecommendationModal}
              onGetRecommendationClick={onGetRecommendationModal}
              country={localCountry}
            />
          ))}
        {showPinWarning && (
          <RelocatePositionModal
            title={t('Relocate position of the pin')}
            text={t('No products available for this location. Please use another location.')}
            notificationColor={colors.yellow40}
            onOkClick={onOkRelocatePinModal}
          />
        )}
        {showLandIsNotArableModal && (
          <ConfirmationModal
            title={t('Field not on arable land')}
            body={t(`Looks like your field is not on arable land. 
                  Please change the location of the pin.`)}
            confirmButtonText={t('Ok')}
            onClickConfirm={handleShowLandNotArableModal}
          />
        )}
        {arableLandLoading && <Spinner />}
        {showAgronomicWeightings && (
          <AgronomicWeightingsDrawer
            showAgronomicWeightings={Boolean(showAgronomicWeightings)}
            mobileActiveTab={mobileActiveTab}
            agronomicWeightings={agronomicWeightings}
            attributes={attributes}
            handleSetShowAgronomicWeightings={handleSetShowAgronomicWeightings}
            handleFillAgronomicWeightings={handleFillAgronomicWeightings}
          />
        )}
      </Root>
      <LandscapeWarningMessageContainer
        className={
          isMobile && orientation === ORIENTATION_TYPE.LANDSCAPE
            ? ORIENTATION_BASED_DISPLAY.SHOW_ON_LANDSCAPE
            : 'hide'
        }
      >
        {t('Landscape for cellphone message')}
      </LandscapeWarningMessageContainer>
    </>
  );
}
